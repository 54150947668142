import { Card, styled, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { ApiCallState } from '../../api';
import { DeliveryDTO } from '../../api/generated';
import { ApiLoadingGuard } from '../ApiLoadingGuard';

export interface DetailCardProps {
  delivery: ApiCallState<DeliveryDTO>;
}

export function DetailCard(props: DetailCardProps) {
  const padding: number = 1; // The unit is rem
  const fontSize: number = 1; // The unit is rem

  const dataListEntry = [
    {
      label: 'Wiegescheinnummer',
      text: props.delivery.result?.delivery_id?.toString(),
    },
    {
      label: 'Sorte',
      text: props.delivery.result?.material_id?.toString() + ' ' + props.delivery.result?.material,
    },
    {
      label: 'AVV',
      text: props.delivery.result?.avv,
    },
    {
      label: 'Anlieferdatum',
      text: new Date(props.delivery?.result?.date || '').toLocaleDateString('de-DE'),
    },
    {
      label: 'Anlieferzeit',
      text: new Date(props.delivery?.result?.date || '').toLocaleTimeString('de-DE'),
    },
    {
      label: 'Gewicht (t)',
      text: props.delivery.result?.netto_weight ? props.delivery.result.netto_weight : '',
    },
    {
      label: 'Baustelle',
      text: props.delivery.result?.construction_site,
    },
    {
      label: 'KFZ Kennzeichen',
      text: props.delivery.result?.numberplate,
    },
  ];

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // Make every nth (odd or even) row a different background color
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    // Hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <Card
      sx={{
        padding: `${padding}rem`,
        borderRadius: '10px',
        overflow: 'hidden',
        height: `calc(100% - 2 * ${padding}rem)`,
        display: 'flex',
        flexDirection: 'column',
      }}
      elevation={8}
    >
      <Typography variant='h6' alignSelf={'flex-start'}>
        Lieferscheindetails
      </Typography>
      <ApiLoadingGuard apiStates={[props.delivery]}>
        <TableContainer sx={{ borderRadius: '10px' }}>
          <Table aria-label='detail table' size='small' sx={{ borderRadius: '10px' }}>
            <TableBody>
              {dataListEntry.map((row) => {
                return (
                  <StyledTableRow key={row.label}>
                    <TableCell component='th' scope='row' sx={{ fontSize: `${fontSize}rem` }}>
                      {row.label}
                    </TableCell>
                    <TableCell align='left' sx={{ fontSize: `${fontSize}rem` }}>
                      {row.text}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ApiLoadingGuard>
    </Card>
  );
}

import { Dispatch, SetStateAction } from 'react';
import { Typography, Switch, Stack, Divider, Container } from '@mui/material';

export interface LiveProgressProps {
  mode: 'live' | 'manual';
  onModeChange: Dispatch<SetStateAction<'live' | 'manual'>>;
}

export function RefreshMode(props: LiveProgressProps) {
  const handleModeSwitch = () => {
    props.onModeChange(props.mode === 'live' ? 'manual' : 'live');
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        paddingTop: 2,
      }}
    >
      <Divider textAlign='left' variant='middle' sx={{ width: '100%', minWidth: '300px' }}>
        <Typography align='left' variant={'h6'} sx={{ alignSelf: 'flex-start' }}>
          Anzeigemodus
        </Typography>
      </Divider>
      <Stack direction='row' spacing={2} alignItems='center' justifyContent={'space-around'}>
        <Typography variant='h6' align='center' color={props.mode === 'manual' ? 'black' : 'gray'}>
          Manuell
        </Typography>
        <Switch checked={props.mode === 'live'} onChange={handleModeSwitch} />
        <Typography variant='h6' align='center' color={props.mode === 'manual' ? 'gray' : 'black'}>
          Live
        </Typography>
      </Stack>
    </Container>
  );
}

import React, { Dispatch } from 'react';
import { Container, Divider, Typography, CircularProgress, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import TodayIcon from '@mui/icons-material/Today';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { ApiCallState } from '../../api';
import { ApiLoadingGuard } from '../ApiLoadingGuard';
import { Activity } from '../../pages/LiveView';
import { ICapturerEvents } from '../../api';
import { TransitionGroup } from 'react-transition-group';
import styles from './ActivityList.module.scss';
import { theme } from '../../Theme';

export interface ActivityListProps {
  activities: ApiCallState<Activity[]>;
  currentActivity: ApiCallState<Activity>;
  onRowSelect: (activity: ApiCallState<Activity>) => void;
  onFetchActivities: Dispatch<ICapturerEvents>;
}

export function ActivityRow(props: {
  activity: Activity;
  getRowClassName?: (activity: Activity) => string;
  getRowStatus: (activity: Activity) => JSX.Element;
  onRowClick: (activity: ApiCallState<Activity>) => void;
}) {
  const [isHovered, setIsHovered] = React.useState(false);

  const [currentTime, setCurrentTime] = React.useState(Date.now());
  React.useEffect(() => {
    setTimeout(() => setCurrentTime(Date.now()), 1000);
  }, [currentTime]);

  const date = new Date(props.activity.capture_start_at);
  let time_string: string;
  if (isNaN(date.getTime())) {
    time_string = 'Ungültiges Datum';
  }
  const passedMinutes = Math.round((currentTime - date.getTime()) / 60000);
  if (passedMinutes < 60) {
    time_string = `${passedMinutes}m`;
  } else if (passedMinutes < 1440) {
    time_string = `${Math.round(passedMinutes / 60)}h`;
  } else {
    time_string = `${Math.round(passedMinutes / 1440)}D`;
  }

  function handleClick() {
    if (!props.activity.finished) return;
    props.onRowClick({
      result: props.activity,
    });
  }

  function getClassName(): string {
    let className = props.getRowClassName ? props.getRowClassName(props.activity) : styles.activity;

    if (className === styles.selected_activity) {
      return className;
    }
    if (className === styles.processing_activity) {
      return className;
    }
    if (className === styles.finished_activity) {
      return isHovered ? styles.hovering_activity : className;
    }

    return className;
  }

  return (
    <div
      className={getClassName()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseUp={() => handleClick()}
    >
      <Grid key={props.activity.capture_start_at} xs={12} height='100%' marginBottom='5px'>
        <Grid container spacing={2} paddingLeft='10px'>
          {/* vor */}
          <Grid xs={2} display='flex' justifyContent='center' alignItems='center'>
            <Typography variant='body1' textAlign='left'>
              {time_string}
            </Typography>
          </Grid>
          {/* Startzeit */}
          <Grid xs={3} display='flex' justifyContent='center' alignItems='center'>
            <Typography variant='body1' textAlign='left'>
              {date.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
            </Typography>
          </Grid>
          {/* Kennzeichen */}
          <Grid xs={5} display='flex' justifyContent='center' alignItems='center'>
            <Typography variant='body1' noWrap textAlign='center'>
              {props.activity.licensePlateText}
            </Typography>
          </Grid>
          {/* Status */}
          <Grid xs={2} display='flex' justifyContent='center' alignItems='center'>
            {props.getRowStatus(props.activity)}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

function ActivityGrid(props: {
  activities: ApiCallState<Activity[]>;
  onRowClick?: (activity: ApiCallState<Activity>) => void;
  getRowClassName: (activity: Activity) => string;
  getRowStatus: (activity: Activity) => JSX.Element;
}) {
  return (
    <div
      className={styles.activity_list}
      style={{
        overflowY: 'scroll',
        overflowX: 'hidden',
        maxHeight: '30vh',
        width: '100%',
        borderRadius: '10px',
        border: '1px solid lightgray',
        position: 'relative',
      }}
    >
      <Grid
        container
        height='100%'
        paddingLeft='10px'
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
          paddingTop: '4px',
          paddingRight: '4px',
          paddingBottom: '2px',
        }}
      >
        <Grid xs={2} display='flex' justifyContent='center' alignItems='center'>
          <AccessTimeIcon />
        </Grid>

        <Grid xs={3} display='flex' justifyContent='center' alignItems='center'>
          <TodayIcon />
        </Grid>

        <Grid display='flex' justifyContent='center' alignItems='center' xs={5}>
          <LocalShippingIcon />
        </Grid>

        <Grid display='flex' justifyContent='center' alignItems='center' xs={2} marginBottom='5px'>
          <Typography variant='body1'>Status</Typography>
        </Grid>
      </Grid>
      {/* Content */}
      <TransitionGroup>
        {props.activities.result?.map((activity, index) => (
          <ActivityRow
            activity={activity}
            onRowClick={props.onRowClick || (() => {})}
            getRowClassName={props.getRowClassName}
            getRowStatus={props.getRowStatus}
          />
        ))}
      </TransitionGroup>
    </div>
  );
}

export function ActivityList(props: ActivityListProps) {
  function getRowClassName(activity: Activity) {
    // selected activity
    if (activity.capture_start_at === props.currentActivity.result?.capture_start_at) {
      return styles.selected_activity;
    }

    if (activity.finished) return styles.activity;

    if (activity.processing || activity.recording || activity.uploading) {
      return styles.processing_activity;
    }

    return styles.activity;
  }

  function getRowStatus(activity: Activity): JSX.Element {
    if (activity.finished) {
      return (
        <CheckCircleIcon htmlColor={activity.hasFeedback ? theme.palette.success.main : theme.palette.primary.main} />
      );
    } else if (activity.processing || activity.uploading) {
      return (
        <Tooltip title='Verarbeitung'>
          <CircularProgress size={25} />
        </Tooltip>
      );
    } else if (activity.recording) {
      return (
        <Tooltip title='Aufnahme'>
          <RadioButtonCheckedIcon htmlColor='#b90505' className={styles.recording_icon} />
        </Tooltip>
      );
    }
    return <div />;
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <Divider textAlign='left' variant='middle' sx={{ width: '100%', minWidth: '300px' }}>
        <Typography align='left' variant={'h6'}>
          Vergangene Aktivitäten
        </Typography>
      </Divider>
      {/* Custom list of rows */}
      <ApiLoadingGuard apiStates={[props.activities]}>
        <ActivityGrid
          activities={props.activities}
          onRowClick={props.onRowSelect}
          getRowClassName={getRowClassName}
          getRowStatus={getRowStatus}
        />
      </ApiLoadingGuard>
    </Container>
  );
}

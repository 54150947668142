import * as React from 'react';
import { Box } from '@mui/system';
import './UserGrid.scss';
import { CreateUser } from './CreateUser';
import { ManageJobSites } from './ManageJobSites';
import { UserAccess } from './UserAccess';

export type RefetchProp = {
  refetch: boolean;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
};

export function UserGrid() {
  const [refetch, setRefetch] = React.useState<boolean>(false);

  return (
    <Box sx={{ paddingBottom: '20px' }}>
      <CreateUser refetch={refetch} setRefetch={setRefetch} />
      <ManageJobSites refetch={refetch} setRefetch={setRefetch} />
      <UserAccess refetch={refetch} setRefetch={setRefetch} />
    </Box>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Button, Card, Checkbox } from '@mui/material';
import { DataGridPro, deDE, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import { RefetchProp } from './UserGrid';
import { useSnackbar } from '../SnackbarProvider';
import { Box } from '@mui/system';
import { ApiCallState, buildQueryParams, PaginatedResult } from '../../api';
import { ApiLoadingGuard } from '../ApiLoadingGuard';
import { JobsiteDTO, JobsiteService, UserDTO, UserService } from '../../api/generated';
import { UserContext } from '../../UserContext';

export function UserAccess({ refetch, setRefetch }: RefetchProp) {
  const { user, reloadUser } = useContext(UserContext);

  const { showSnackbar } = useSnackbar();
  const apiRef = useGridApiRef();

  const [users, setUsers] = useState<ApiCallState<UserDTO[]>>({});
  const [jobsites, setJobsites] = useState<ApiCallState<JobsiteDTO[]>>({});
  const [loadingMap, setLoadingMap] = useState<{ users: boolean; jobsites: boolean }>({
    users: false,
    jobsites: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoadingMap((prev) => ({ ...prev, users: true }));
    (async () => {
      if (!user) return;
      return (await UserService.userControllerGetUsers({
        query: buildQueryParams({
          filter: {
            filterFields: [
              {
                filterField: 'organisationId' as unknown as Record<string, any>,
                value: user?.organisation.id || '',
                operatorValue: 'equals',
              },
            ],
          },
        }),
      })) as PaginatedResult<UserDTO>;
    })()
      .then((result) => {
        if (result) {
          const { data, total } = result;
          setUsers({ result: data });
        }
      })
      .catch((err) => {
        setUsers({ error: `${err.message}` });
      })
      .finally(() => {
        setLoadingMap((prev) => ({ ...prev, users: false }));
      });
  }, [refetch, user]);

  useEffect(() => {
    (async () => {
      setLoadingMap((prev) => ({ ...prev, jobsites: true }));
      if (!user) return;
      return (await JobsiteService.jobsiteControllerGetJobsites({
        organisationId: user?.organisation.id || '',
      })) as PaginatedResult<JobsiteDTO>;
    })()
      .then((result) => {
        if (result) {
          const { data, total } = result;
          setJobsites({ result: data });
        }
      })
      .catch((err) => {
        setJobsites({ error: `${err.message}` });
      })
      .finally(() => {
        setLoadingMap((prev) => ({ ...prev, jobsites: false }));
      });
  }, [refetch, user]);

  useEffect(() => {
    setLoading(Object.values(loadingMap).some((v) => v));
  }, [JSON.stringify(loadingMap)]);

  function handleCheckboxJobsiteChange(userId: string, jobsiteId: string, currentValue: boolean) {
    const affectedUser = users.result?.find((u) => u.id === userId);
    let userJobsiteIdList = affectedUser?.jobsiteIds?.map((j) => j) || [];
    if (!currentValue) {
      // push jobsiteId to user jobsites
      userJobsiteIdList?.push(jobsiteId);
    } else {
      // remove jobsiteId from user jobsites
      userJobsiteIdList = userJobsiteIdList?.filter((j) => j !== jobsiteId);
    }

    (async () => {
      return await UserService.userControllerUpdateUser({
        authId: affectedUser?.authId || '',
        requestBody: {
          jobsiteIds: userJobsiteIdList,
        },
      });
    })()
      .then(() => {
        showSnackbar(currentValue ? 'Zugriff entfernt' : 'Zugriff hinzugefügt', 'success');
        if (affectedUser?.id === user?.id) reloadUser();
        setRefetch(!refetch);
      })
      .catch((err) => {
        showSnackbar(err.message, 'error');
      });
  }

  function handleCheckboxAdminChange(userId: string, currentValue: boolean) {
    (async () => {
      const affectedUser = users.result?.find((u) => u.id === userId);
      await UserService.userControllerUpdateUser({
        authId: affectedUser?.authId || '',
        requestBody: {
          admin: !currentValue,
        },
      });
    })()
      .then(() => {
        showSnackbar(currentValue ? 'Administrator/in entfernt' : 'Administrator/in hinzugefügt', 'success');
        setRefetch(!refetch);
      })
      .catch((err) => {
        showSnackbar(err.message, 'error');
      });
  }

  function handleRemoveUser() {
    const rowsToDelete = apiRef.current.getSelectedRows();
    (async () => {
      const affectedUser = users.result?.find((u) => u.id === rowsToDelete.keys().next().value);
      return await Promise.all(
        Array.from(rowsToDelete.keys()).map((userId) =>
          UserService.userControllerDeleteUser({ authId: affectedUser?.authId || '' })
        )
      );
    })()
      .then(() => {
        showSnackbar('Nutzer erfolgreich entfernt', 'success');
        setRefetch(!refetch);
      })
      .catch((err: any) => {
        showSnackbar(err.message, 'error');
      });
  }

  return (
    <>
      <Typography variant='h5' sx={{ marginTop: 2 }}>
        Benutzerberechtigungen
      </Typography>
      <Card
        sx={{
          borderRadius: '10px',
          marginTop: 1,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 400,
        }}
        elevation={8}
      >
        <ApiLoadingGuard apiStates={[users, jobsites]}>
          <DataGridPro
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={
              users.result?.map((user: UserDTO) => ({
                ...user,
                ...Object.fromEntries(user.jobsiteIds.map((j) => [`jobsite_${j}`, true])),
              })) || []
            }
            columns={[
              {
                field: 'displayName',
                headerName: 'Name',
              },
              {
                field: 'email',
                headerName: 'E-Mail',
                width: 200,
              },
              {
                field: 'admin',
                headerName: 'Administrator/in',
                headerAlign: 'center',
                width: 120,
                align: 'center',
                renderCell: (params: GridRenderCellParams) => (
                  <strong>
                    <Checkbox
                      checked={params?.value || false}
                      onChange={() => handleCheckboxAdminChange(params.id.toString(), params?.value || false)}
                    />
                  </strong>
                ),
              } as any, // cast to any, otherwise MUI throws an error with the align property
            ].concat(
              jobsites.result?.map((j) => {
                return {
                  field: `jobsite_${j.id}`,
                  headerName: j.name,
                  headerAlign: 'center',
                  width: 120,
                  align: 'center',
                  renderCell: (params: GridRenderCellParams) => (
                    <strong>
                      <Checkbox
                        checked={params?.value || false}
                        onChange={() => handleCheckboxJobsiteChange(params.id.toString(), j.id, params?.value || false)}
                      />
                    </strong>
                  ),
                };
              }) || []
            )}
            checkboxSelection
            disableSelectionOnClick
            disableMultipleSelection={true}
            apiRef={apiRef}
            getCellClassName={(params) => {
              if (/^jobsite/.test(params.field)) return '';
              if (params.field === 'admin' || params.field === 'id') return '';

              return params.row.activated ? '' : 'unregistered-user-cell';
            }}
            getRowClassName={(params) => {
              return params.row.activated ? '' : 'unregistered-user-row';
            }}
            hideFooter={true}
            loading={loading}
          />
          <Box sx={{ display: 'flex' }}>
            <Button sx={{ marginTop: 2 }} variant='contained' onClick={handleRemoveUser}>
              Nutzer/in löschen
            </Button>
          </Box>
        </ApiLoadingGuard>
      </Card>
    </>
  );
}

import { ApiCallState } from '../../api';
import { Container, Divider, List, Typography } from '@mui/material';
import { ListEntry } from '../ListEntry';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TodayIcon from '@mui/icons-material/Today';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { ApiLoadingGuard } from '../ApiLoadingGuard';
import { Activity } from '../../pages/LiveView';
import { useEffect, useState } from 'react';

export interface CurrentActivityProps {
  activity: ApiCallState<Activity>;
}

export function CurrentActivity(props: CurrentActivityProps) {
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    setTimeout(() => setCurrentTime(Date.now()), 1000);
  }, [currentTime]);

  function dateToPassedTime(date: Date) {
    const passedMinutes = Math.round((currentTime - date.getTime()) / 60000);
    if (passedMinutes < 60) {
      // Minuten
      return `${passedMinutes} ${passedMinutes > 1 ? 'Minuten' : 'Minute'}`;
    } else if (passedMinutes < 1440) {
      // Stunden
      return `${Math.round(passedMinutes / 60)} ${Math.round(passedMinutes / 60) > 1 ? 'Stunden' : 'Stunde'}`;
    } else {
      // Tage
      return `${Math.round(passedMinutes / 1440)} ${Math.round(passedMinutes / 1440) > 1 ? 'Tage' : 'Tag'}`;
    }
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        paddingTop: 2,
      }}
    >
      <Divider textAlign='left' variant='middle' sx={{ width: '100%', minWidth: '300px' }}>
        <Typography align='left' variant={'h6'} sx={{ alignSelf: 'flex-start' }}>
          Aktuelle Aktivität
        </Typography>
      </Divider>
      <ApiLoadingGuard apiStates={[props.activity]}>
        <List
          sx={{
            flexDirection: 'row',
            alignSelf: 'flex-start',
            justifyItems: 'space-evenly',
            width: '100%',
          }}
        >
          <ListEntry
            icon={<AccessTimeIcon />}
            textLabel='vergange Zeit'
            textEntry={dateToPassedTime(new Date(props.activity.result?.capture_start_at || ''))}
          />
          <ListEntry
            icon={<TodayIcon />}
            textLabel='Datum'
            textEntry={new Date(props.activity.result?.capture_start_at || '').toLocaleString('de-DE')}
          />
          <ListEntry
            icon={<LocalShippingIcon />}
            textLabel='Kennzeichen'
            textEntry={props.activity.result?.licensePlateText}
          />
        </List>
      </ApiLoadingGuard>
    </Container>
  );
}

import { createContext, useContext, useState, PropsWithChildren } from 'react';

export interface SpinnerContextProps {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const SpinnerContext = createContext<SpinnerContextProps | undefined>(undefined);

export const useSpinner = () => {
  const context = useContext(SpinnerContext);
  if (!context) {
    throw new Error('useSpinner must be used within a SpinnerProvider');
  }
  return context;
};

export function SpinnerProvider(props: PropsWithChildren) {
  const [loading, setLoading] = useState(false);

  return <SpinnerContext.Provider value={{ loading, setLoading }}>{props.children}</SpinnerContext.Provider>;
}

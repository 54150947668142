import React from 'react';

import { Card, Typography } from '@mui/material';
import { ApiCallState } from '../../api';
import { PredictionDTO } from '../../api/generated';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import { BarChart, BarSeriesType } from '@mui/x-charts';
import { ApiLoadingGuard } from '../ApiLoadingGuard';
import { PredictionError } from '../LiveView/PredictionError';
import { theme } from '../../Theme';

export interface DetailViewPredictionCardProps {
  height: string;
  prediction: ApiCallState<PredictionDTO>;
}

export default function DetailViewPredictionCard(props: DetailViewPredictionCardProps) {
  const [series, setSeries] = React.useState<MakeOptional<BarSeriesType, 'type'>[]>([]);

  const padding: number = 1; // The unit is rem

  React.useEffect(() => {
    if (!props.prediction.result) return;

    setSeries([
      {
        label: 'Prognose',
        data: props.prediction.result?.estimate.map((x) => {
          return x.probability * 100;
        }),
        color: theme.palette.primary.light,
      },
    ]);
  }, [props.prediction]);

  return (
    <Card
      sx={{
        borderRadius: '10px',
        padding: `${padding}rem`,
        display: 'flex',
        flexDirection: 'column',
        height: props.height,
        position: 'relative',
      }}
      elevation={8}
    >
      <Typography variant='h6'>Materialprognose</Typography>
      <ApiLoadingGuard apiStates={[props.prediction]} error_display={PredictionError}>
        <BarChart
          margin={{ top: 10, right: 100, bottom: 40, left: 40 }}
          xAxis={[{ data: props.prediction.result?.estimate.map((x) => x.displayName), scaleType: 'band' }]}
          yAxis={[{ scaleType: 'linear', min: 0, max: 100 }]}
          series={series}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              padding: 0,
            },
          }}
          slots={{
            // This disables tooltips
            axisContent: (_) => {
              return <></>;
            },
          }}
          sx={{
            // This is neccessary, because otherwise the y-axis keeps expanding if the viewport is too thin (responsive). idk why
            '&': {
              position: 'absolute',
            },
          }}
        />
      </ApiLoadingGuard>
    </Card>
  );
}

import { createTheme } from '@mui/material/styles';
import createTypography from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    list_item: React.CSSProperties;
    warning: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    list_item?: React.CSSProperties;
    warning?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    list_item: true;
    warning: true;
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1600,
      xl: 1800,
    },
  },

  palette: {
    primary: {
      main: '#0A1F5C',
    },
    secondary: {
      main: '#d56636',
    },
  },
  typography: {
    list_item: {},
    warning: {},
  },
});

theme.typography = createTypography(theme.palette, {
  fontFamily: 'Raleway',
  h6: {
    fontFeatureSettings: `'lnum' on`,
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  },
  body1: {
    fontFeatureSettings: `'lnum' on`,
    fontSize: '0.8rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  },
  body2: {
    fontFeatureSettings: `'lnum' on`,
    fontSize: '0.8rem',
  },
  subtitle2: {
    fontFeatureSettings: `'lnum' on`,
    fontSize: '0.8rem',
  },
  subtitle1: {
    fontFeatureSettings: `'lnum' on`,
    fontSize: '0.8rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  },
  list_item: {
    fontFeatureSettings: `'lnum' on`,
    color: 'red',
  },
  warning: {
    fontFeatureSettings: `'lnum' on`,
    color: '282828',
    fontSize: '0.8rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.4rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.0rem',
    },
  },
});

import { Container, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export function ImageCardError() {
  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', margin: 'auto', minWidth: 'auto' }}>
      <ErrorOutlineIcon fontSize='large' sx={{ margin: 'auto' }} />
      <Typography variant='h6' textAlign={'center'} sx={{ margin: 'auto' }}>
        Es konnte kein Bild angezeigt werden.
      </Typography>
    </Container>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { ListItemLink } from './ListItemLink';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import DocumentScannerSharpIcon from '@mui/icons-material/DocumentScannerSharp';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import {
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  AppBar,
  Box,
  Drawer,
  List,
  ListItem,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { UserContext } from '../UserContext';
import { RESPONSIVE_DRAWER_WIDTH } from '../Constants';

export function ResponsiveDrawer() {
  const { user } = React.useContext(UserContext);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { logout } = useAuth0();

  const drawer = (
    <Box>
      <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src='/logo_grey.png' alt='Optocycle Logo' style={{ height: '35px' }} />
      </Toolbar>
      <List className='list' sx={{ width: '100%' }}>
        <ListItem disablePadding>
          <ListItemLink to='/documentation' primary='Dokumentation' icon={<DocumentScannerSharpIcon />} />
        </ListItem>
        <ListItem disablePadding>
          <ListItemLink to='/live-prediction' primary='Live Prognose' icon={<CenterFocusWeakIcon />} />
        </ListItem>
        {/* TODO: at the moment this link points to an initial jobsite selection. in the future it should point to the jobsite management */}
        <ListItem disablePadding>
          <ListItemLink to='/initial-jobsite-select' primary='Standorte' icon={<LocationOnIcon />} />
        </ListItem>
        <ListItem disablePadding>
          <ListItemLink to='/user-admin' primary='Administration' icon={<PersonIcon />} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box>
      <AppBar
        position='fixed'
        sx={{
          width: { lg: `calc(100% - ${RESPONSIVE_DRAWER_WIDTH}px)` },
          ml: { lg: `${RESPONSIVE_DRAWER_WIDTH}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
            {user?.organisation?.name}
          </Typography>
          <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
            {user?.preferredJobsite?.name}
          </Typography>

          <Typography variant='subtitle1'>{user?.displayName}</Typography>
          <Box sx={{}}>
            <Tooltip title='Einstellungen'>
              <IconButton onClick={handleOpenUserMenu}>
                <AccountCircleIcon htmlColor='white' fontSize='large' />
              </IconButton>
            </Tooltip>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key='logout' onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                <Typography textAlign='center'>Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component='nav'
        sx={{ width: { lg: RESPONSIVE_DRAWER_WIDTH }, flexShrink: { lg: 0 } }}
        aria-label='mailbox folders'
      >
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: RESPONSIVE_DRAWER_WIDTH },
          }}
        >
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
              {user?.organisation?.name}
            </Typography>
            <Typography variant='subtitle1'>{user?.displayName}</Typography>
            <Box sx={{}}>
              <Tooltip title='Einstellungen'>
                <IconButton onClick={handleOpenUserMenu}>
                  <AccountCircleIcon htmlColor='white' fontSize='large' />
                </IconButton>
              </Tooltip>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key='logout' onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                  <Typography textAlign='center'>Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Drawer>
      </Box>
      <Box
        component='nav'
        sx={{ width: { lg: RESPONSIVE_DRAWER_WIDTH }, flexShrink: { lg: 0 } }}
        aria-label='mailbox folders'
      >
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: RESPONSIVE_DRAWER_WIDTH },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: RESPONSIVE_DRAWER_WIDTH },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

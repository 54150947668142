import { Config } from './typing';

export const config: Config = {
  basename: '/',
  muiLicenseKey: '357961255f667f2df1cbb65ba5d13726Tz04MjE1NixFPTE3MzY3NjEzMjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  auth0: {
    domain: 'optocycle.eu.auth0.com',
    clientId: '6k79Pp3Y5VcT6I4L9Ohv00GY171cLLeo',
    audience: 'https://desk.optocycle.com/api',
  },
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { RefetchProp } from './UserGrid';
import { useSnackbar } from '../SnackbarProvider';
import { JobsiteDTO, JobsiteService } from '../../api/generated';
import { UserContext } from '../../UserContext';
import { ApiCallState, PaginatedResult } from '../../api';

export function ManageJobSites({ refetch, setRefetch }: RefetchProp) {
  const { user, reloadUser } = useContext(UserContext);

  const { showSnackbar } = useSnackbar();

  const [selectedJobsiteId, setSelectedJobsiteId] = useState<string | undefined>();
  const [jobsites, setJobsites] = useState<ApiCallState<JobsiteDTO[]>>({});
  const [newName, setNewName] = useState<string>('');

  useEffect(() => {
    (async () => {
      return (await JobsiteService.jobsiteControllerGetJobsites({
        organisationId: user?.organisation.id || '',
      })) as PaginatedResult<JobsiteDTO>;
    })()
      .then((result) => {
        if (result) {
          const { data, total } = result;
          setJobsites({ result: data });
        }
      })
      .catch((err) => {
        setJobsites({ error: `${err.message}` });
      });
  }, [refetch]);

  function handleRenameJobsite() {
    (async () => {
      if (selectedJobsiteId) {
        await JobsiteService.jobsiteControllerUpdateJobsite({
          organisationId: user?.organisation.id || '',
          jobsiteId: selectedJobsiteId,
          requestBody: {
            name: newName,
          },
        });
      } else {
        throw new Error('no jobsite selected');
      }
    })()
      .then(() => {
        showSnackbar('Bauhof umbenannt', 'success');
        setRefetch(!refetch);
        reloadUser();
        setNewName('');
      })
      .catch((err) => {
        showSnackbar(err.message, 'error');
      });
  }

  return (
    <>
      <Typography variant='h5' sx={{ marginTop: 2 }}>
        Standortverwaltung
      </Typography>
      <Card sx={{ borderRadius: '10px', marginTop: 1, padding: 2, display: 'flex' }} elevation={8}>
        <FormControl sx={{ flexGrow: 0.5, marginRight: 2 }}>
          <InputLabel id='select-jobsite-label'>Standort auswählen</InputLabel>
          <Select
            labelId='select-jobsite-label'
            label='Standort auswählen'
            MenuProps={{ disableScrollLock: true }}
            value={selectedJobsiteId}
            onChange={(e) => {
              setSelectedJobsiteId(e.target.value);
            }}
          >
            {jobsites.result?.map((j, idx) => (
              <MenuItem value={j.id} key={idx}>
                {` ${j.name} `}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          sx={{ flexGrow: 0.5, marginRight: 2 }}
          label='Neuer Name'
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />

        <Button sx={{ flexGrow: 0.25 }} variant='contained' onClick={handleRenameJobsite} disabled={newName.length < 3}>
          Standort umbenennen
        </Button>
      </Card>
    </>
  );
}

import React from 'react';
import Box from '@mui/material/Box';
import { ResponsiveDrawer } from '../components/ResponsiveDrawer';
import { ImageCard } from '../components/ImageCard';
import { Prediction } from '../components/LiveView/Prediction';
import { ActivityList } from '../components/LiveView/ActivityList';
import { useAuth0 } from '@auth0/auth0-react';
import { Socket, io } from 'socket.io-client';
import { UserContext } from '../UserContext';
import { RefreshMode } from '../components/LiveView/RefreshMode';
import { CurrentActivity } from '../components/LiveView/CurrentActivity';
import { Card } from '@mui/material';
import { ApiCallState, ICapturerEvents, blobToDataURL } from '../api';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { RESPONSIVE_DRAWER_HEIGHT } from '../Constants';
import { ActivityService, LicensePlateDTO, MaterialClassificationDTO, OpenAPI } from '../api/generated';
import { useSnackbar } from '../components/SnackbarProvider';

const liveSocket: Socket = io({
  path: '/api/live.socket/',
  autoConnect: false,
});
liveSocket.on('connect', () => {
  console.log('WebSocket connection established');
});
liveSocket.on('disconnect', () => {
  console.log('WebSocket connection disconnected');
});
liveSocket.on('error', (error) => {
  console.log('WebSocket connection closed');
});
liveSocket.on('connect_error', (error) => {
  console.log('WebSocket connection closed');
});

export class Activity {
  private started: boolean = true;
  private uploaded: boolean = false;
  private captured: boolean = false;
  private stitched: boolean = false;
  private _licenseplate: LicensePlateDTO | undefined = undefined;
  private _feedback: MaterialClassificationDTO[] = [];

  constructor(readonly capture_start_at: string) {}

  get recording() {
    return this.started && !this.uploaded;
  }

  set recording(recording: boolean) {
    this.started = recording;
  }

  get uploading() {
    return this.started && this.uploaded && !this.captured;
  }

  set uploading(uploading: boolean) {
    this.started = uploading ? true : this.started;
    this.uploaded = uploading;
  }

  get processing() {
    return this.started && this.uploaded && this.captured && !this.stitched;
  }

  set processing(processing: boolean) {
    this.started = processing ? true : this.started;
    this.uploaded = processing ? true : this.uploaded;
    this.captured = processing;
  }

  get finished() {
    return this.stitched;
  }

  set finished(finished: boolean) {
    this.started = finished ? true : this.started;
    this.uploaded = finished ? true : this.uploaded;
    this.captured = finished ? true : this.captured;
    this.stitched = finished;
  }

  get licensePlateText() {
    return this._licenseplate?.ocr_text?.toUpperCase() || '-';
  }

  get licensePlate() {
    return this._licenseplate;
  }

  set licensePlate(lp: LicensePlateDTO | undefined) {
    this._licenseplate = lp;
  }

  set feedback(feedback: MaterialClassificationDTO[]) {
    this._feedback = feedback;
  }

  get feedback() {
    return this._feedback;
  }

  get hasFeedback() {
    return this._feedback.length > 0;
  }
}

export function LiveView() {
  const { getAccessTokenSilently } = useAuth0();
  const { showSnackbar } = useSnackbar();
  const { user } = React.useContext(UserContext);

  const [activities, dispatch] = React.useReducer(activitiesReducer, { result: undefined });
  const [debouncedActivities, setDebouncedActivities] = React.useState<ApiCallState<Activity[]>>({ result: undefined });
  const [currentActivity, setCurrentActivity] = React.useState<ApiCallState<Activity>>({ result: undefined });
  const [mode, setMode] = React.useState<'live' | 'manual'>('live');
  const [image, setImage] = React.useState<ApiCallState<string>>({});

  liveSocket.on('event', async (data: string) => {
    const event = JSON.parse(data);
    dispatch(event);
  });

  async function getLicensePlate(capture_start_at: string): Promise<LicensePlateDTO | undefined> {
    let lp: LicensePlateDTO | undefined = undefined;
    try {
      lp = await ActivityService.activityControllerGetActivityLicensePlate({
        jobsiteId: user?.preferredJobsite?.id || '',
        captureStartAt: capture_start_at,
      });
    } catch (error: any) {
      if (error === 'Not Found') {
        lp = undefined;
      } else {
        console.error(error);
      }
    }
    return lp;
  }

  function activitiesReducer(state: ApiCallState<Activity[]>, action: ICapturerEvents): ApiCallState<Activity[]> {
    if (action.type === 'license_plate_read') return state;

    // only regard messages less than 1 day old
    const date = new Date(action.capture_start_at);
    const now = new Date();
    if (now.getTime() - date.getTime() > 24 * 60 * 60 * 1000) return state;

    if (state.result === undefined) {
      state.result = [];
    }

    let index = state.result?.findIndex((activity) => activity.capture_start_at === action.capture_start_at);
    if (index === -1) {
      state.result?.unshift(new Activity(action.capture_start_at));
      index = 0;
    } else if (state.result[index].finished) {
      return state;
    }

    switch (action.type) {
      case 'activity_started':
        state.result[index].recording = true;
        break;
      case 'activity_ended':
        state.result[index].uploading = true;
        break;
      case 'datapoint_uploaded':
        state.result[index].processing = true;
        break;
      case 'stitching_finished':
        state.result[index].finished = true;
        break;
    }
    // sort activities by capture_start_at (latest first)
    state.result?.sort((a, b) => {
      return new Date(b.capture_start_at).getTime() - new Date(a.capture_start_at).getTime();
    });

    return { result: state.result };
  }

  // subscribe to live socket on mount
  React.useEffect(() => {
    (async () => {
      return await getAccessTokenSilently();
    })().then((token) => {
      liveSocket.auth = { token: token };
      liveSocket.io.opts.query = { jobsiteId: user?.preferredJobsite?.id };
      liveSocket.connect();
    });
    return () => {
      liveSocket.disconnect();
    };
  }, []);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedActivities(activities);
    }, 1000); // delay in ms

    return () => {
      clearTimeout(handler);
    };
  }, [JSON.stringify(activities)]);

  // set latestActivity, pull licenseplates and get feedback
  React.useEffect(() => {
    (async () => {
      if (activities.result === undefined) return;

      if (mode === 'live') {
        const finishedActivities = activities.result.filter((activity) => activity.finished);
        if (
          finishedActivities.length > 0 &&
          finishedActivities[0].capture_start_at !== currentActivity.result?.capture_start_at
        ) {
          setCurrentActivity({ result: finishedActivities[0] });
        }
      }

      // license plates
      const licensePlatePromises = activities.result.map(async (activity, index) => {
        if ((activity.uploading || activity.processing || activity.finished) && activity.licensePlate === undefined) {
          const licensePlate = await getLicensePlate(activity.capture_start_at);
          return { index, licensePlate };
        }
        return null;
      });

      const licensePlateResults = await Promise.all(licensePlatePromises);

      licensePlateResults.forEach((result) => {
        if (result) {
          const { index, licensePlate } = result;
          if (activities.result) {
            activities.result[index].licensePlate = licensePlate;
          }
        }
      });

      // feedback
      const feedbackPromises = activities.result.map(async (activity, index) => {
        if (activity.finished && !activity.hasFeedback) {
          const feedback = await ActivityService.activityControllerGetFeedbacks({
            jobsiteId: user?.preferredJobsite?.id || '',
            captureStartAt: activity.capture_start_at,
          });
          return { index, feedback };
        }
        return null;
      });

      const feedbackResults = await Promise.all(feedbackPromises);

      feedbackResults.forEach((result) => {
        if (result) {
          const { index, feedback } = result;
          if (activities.result) {
            activities.result[index].feedback = feedback.estimate;
          }
        }
      });
    })();
  }, [JSON.stringify(debouncedActivities), mode]);

  function handleRowSelect(activity: ApiCallState<Activity>) {
    setCurrentActivity(activity);
    setMode('manual');
  }

  function handleOnFeedbackChange(feedback: MaterialClassificationDTO[], activity: Activity) {
    for (const act of activities.result || []) {
      if (act.capture_start_at === activity.capture_start_at) {
        act.feedback = feedback;
      }
    }
  }

  async function pictureDownload(capture_start_at: string | undefined): Promise<string> {
    if (!capture_start_at) return '';
    try {
      const blob = await ActivityService.activityControllerGetActivityImage({
        jobsiteId: user?.preferredJobsite?.id || '',
        captureStartAt: capture_start_at,
      });

      const dataUrl = await blobToDataURL(blob);
      return dataUrl;
    } catch (err) {
      console.error(err);
      showSnackbar('Fehler beim Laden des Bildes', 'error');
      return '';
    }
  }

  React.useEffect(() => {
    (async () => {
      setImage({});
      let img = '';
      try {
        img = await pictureDownload(currentActivity.result?.capture_start_at);
      } catch (err) {
        console.error(err);
      }
      return img;
    })()
      .then((img) => {
        if (!img) return;
        setImage({ result: img });
      })
      .catch((err) => {
        console.error({ error: `${err.message}` });
      });
  }, [currentActivity]);

  return (
    <Box display={'flex'} sx={{ overflow: 'hide' }}>
      <ResponsiveDrawer />
      <Grid
        container
        spacing={2}
        sx={{
          padding: 2,
          position: 'relative',
          top: RESPONSIVE_DRAWER_HEIGHT,
          width: '100%',
        }}
      >
        {/* Image and Prediction Column */}
        <Grid sm={12} md={8} container spacing={1}>
          <Grid xs={12}>
            <ImageCard image={image} height='30vh' />
          </Grid>
          <Grid xs={12}>
            <Prediction height='30vh' activity={currentActivity} onFeedbackChange={handleOnFeedbackChange} />
          </Grid>
        </Grid>
        {/* LiveProgress, CurrentActivity and ActivityList Column */}
        <Grid sm={12} md={4}>
          <Card
            sx={{
              flex: 1,
              borderRadius: '10px',
              height: '100%',
              maxHeight: '67vh',
              overflow: 'scroll',
            }}
            elevation={8}
          >
            <RefreshMode mode={mode} onModeChange={setMode} />
            <CurrentActivity activity={currentActivity} />
            <ActivityList
              activities={activities}
              currentActivity={currentActivity}
              onRowSelect={handleRowSelect}
              onFetchActivities={dispatch}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

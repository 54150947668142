import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { theme } from './Theme';
import { AppRouter } from './AppRouter';
import { SpinnerProvider } from './SpinnerContext';
import { Spinner } from './components/GlobalSpinner';
import { responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { SnackbarProvider } from './components/SnackbarProvider';
import { config } from './config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

//LicenseKey of our MUI-PRO-LICENCE - it is designed to be public
LicenseInfo.setLicenseKey(config.muiLicenseKey);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={responsiveFontSizes(theme, { factor: 1 })}>
      <SpinnerProvider>
        <SnackbarProvider>
          <Spinner />
          <AppRouter />
        </SnackbarProvider>
      </SpinnerProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { ListItem, ListItemText, ListItemIcon } from '@mui/material';

export interface ListEntryProps {
  icon?: any;
  textLabel: string;
  textEntry?: string;
}

export function ListEntry(props: ListEntryProps) {
  return (
    <ListItem sx={{ display: 'flex' }}>
      <ListItemIcon sx={{ flex: 1 }}>{props.icon}</ListItemIcon>
      <ListItemText
        sx={{ flex: 3, marginRight: '1rem' }}
        primary={props.textLabel}
        primaryTypographyProps={{
          fontFamily: 'Raleway',
          color: 'black',
          variant: 'list_item',
          fontWeight: 'medium',
        }}
      />
      <ListItemText
        sx={{ flex: 3 }}
        primary={props.textEntry}
        primaryTypographyProps={{
          fontFamily: 'Raleway',
          variant: 'list_item',
          color: '#454545',
          fontWeight: 'medium',
          textAlign: 'left',
        }}
      />
    </ListItem>
  );
}

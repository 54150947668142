import React from 'react';
import { useSpinner } from '../SpinnerContext';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export function Spinner() {
  const { loading } = useSpinner();

  if (!loading) return null;

  return (
    <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress size={100} color='primary' />
    </Box>
  );
}

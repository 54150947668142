import React from 'react';
import { BasicDataGrid } from '../components/BasicDataGrid';
import { ResponsiveDrawer } from '../components/ResponsiveDrawer';
import Box from '@mui/material/Box';
import { Drawer } from '@mui/material';
import { DetailView } from '../components/DetailView/DetailView';
import { RESPONSIVE_DRAWER_HEIGHT, RESPONSIVE_DRAWER_WIDTH } from '../Constants';
import { ApiCallState } from '../api';
import { DeliveryDTO } from '../api/generated';

export function Documentation() {
  const [delivery, setDelivery] = React.useState<ApiCallState<DeliveryDTO>>();
  const [isOverlayShown, setOverlayShown] = React.useState(false);
  const [currentlySelectedRowIndex, setCurrentlySelectedRowIndex] = React.useState<number>();
  const [numberOfRowsCurrentlyShown, setNumberOfRowsCurrentlyShown] = React.useState<number>();
  const [firstElementInTableSelected, setFirstElementInTableSelected] = React.useState<boolean>(false);
  const [lastElementInTableSelected, setLastElementInTableSelected] = React.useState<boolean>(false);

  const padding: number = 1; // The unit is rem

  enum RequestedDelivery {
    NEXT,
    PREVIOUS,
  }

  /**
   * 
    Only a placeholder behavior until it is decided how the table is shown when the new
    filters are in place. This disables the next button of the DetailView if the last item
    is selected and the previous button if the first one is selected
   */
  const checkForFirstAndLastElementSelected = (indexOfRequestedRow: number): void => {
    setFirstElementInTableSelected(indexOfRequestedRow === 0);
    setLastElementInTableSelected(indexOfRequestedRow === numberOfRowsCurrentlyShown!! - 1);
  };

  const handleClickOnDataGridRow = (value: DeliveryDTO, newlySelectedRowIndex: number) => {
    setDelivery({ result: value });
    setCurrentlySelectedRowIndex(newlySelectedRowIndex);
    if (!isOverlayShown) setOverlayShown(true);

    checkForFirstAndLastElementSelected(newlySelectedRowIndex);
  };

  function handleDifferentDeliveryRequested(requestedDelivery: RequestedDelivery) {
    const indexOfRequestedRow =
      requestedDelivery === RequestedDelivery.NEXT ? currentlySelectedRowIndex!! + 1 : currentlySelectedRowIndex!! - 1;
    const isRequestedRowIndexInRange = indexOfRequestedRow >= 0 && indexOfRequestedRow < numberOfRowsCurrentlyShown!!;
    if (!isRequestedRowIndexInRange) return;
    setCurrentlySelectedRowIndex(indexOfRequestedRow);

    checkForFirstAndLastElementSelected(indexOfRequestedRow);
  }

  return (
    <Box display={'flex'}>
      <ResponsiveDrawer />
      <Box
        sx={{
          height: `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT}px - 2*${padding}rem)`,
          width: { xs: '100%', lg: `calc(100% - ${RESPONSIVE_DRAWER_WIDTH}px)`, md: '100%' },
          top: RESPONSIVE_DRAWER_HEIGHT,
          position: 'absolute',
          left: { xs: 0, lg: RESPONSIVE_DRAWER_WIDTH },
        }}
      >
        <Box
          style={{
            padding: `${padding}rem`,
            height: '100%',
          }}
        >
          <BasicDataGrid
            onRowSelect={handleClickOnDataGridRow}
            requestedRowIndex={currentlySelectedRowIndex!!}
            setNumberOfShownRows={setNumberOfRowsCurrentlyShown}
          />
        </Box>
      </Box>
      <Box
        style={{
          height: `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT}px)`,
        }}
      >
        <Drawer
          open={isOverlayShown}
          onClose={() => setOverlayShown(false)}
          anchor='right'
          /*By default, the overlay darkens everything on being opened.
             This is how to remove this effect*/
          ModalProps={{
            slotProps: {
              backdrop: {
                invisible: true,
              },
            },
          }}
          PaperProps={{
            sx: {
              height: `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT})`,
              width: { xs: '100%', lg: `calc(80% - ${RESPONSIVE_DRAWER_WIDTH}px)`, md: '80%' },
              top: RESPONSIVE_DRAWER_HEIGHT,
            },
          }}
        >
          <DetailView
            delivery={delivery!!}
            nextButtonEnabled={!lastElementInTableSelected}
            previousButtonEnabled={!firstElementInTableSelected}
            hideOverlay={() => setOverlayShown(false)}
            requestNextItem={() => handleDifferentDeliveryRequested(RequestedDelivery.NEXT)}
            requestPreviousItem={() => handleDifferentDeliveryRequested(RequestedDelivery.PREVIOUS)}
          />
        </Drawer>
      </Box>
    </Box>
  );
}

import { PropsWithChildren } from 'react';
import { ApiCallState } from '../api';
import { CircularProgress, Container, Typography } from '@mui/material';
import { useSnackbar } from './SnackbarProvider';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export interface LoadingGuardProps {
  apiStates: ApiCallState<any>[];
  error_display?: () => JSX.Element;
}

export const basic_error_display = (): JSX.Element => (
  <Container sx={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
    <ErrorOutlineIcon fontSize='large' sx={{ margin: 'auto' }} />
    <Typography variant='h6' textAlign={'center'} sx={{ margin: 'auto' }}>
      Es ist ein Fehler aufgetreten
    </Typography>
  </Container>
);

export const basic_loading_display = (): JSX.Element => <CircularProgress sx={{ margin: 'auto' }} />;

export function ApiLoadingGuard(props: PropsWithChildren<LoadingGuardProps>): JSX.Element {
  const { apiStates, error_display = basic_error_display, children } = props;
  const { showSnackbar } = useSnackbar();

  // Success case: No errors found & finished loading => Show content
  const hasAllResults: boolean = apiStates.every((v) => !!v && !!v.result);
  if (hasAllResults) return <>{children}</>;

  // Error case: Api errors present => Communicate errors
  const errors: any[] = apiStates.filter((v) => !!v && !!v.error).map((v) => v.error);
  if (errors.length > 0) {
    // console.error('An error occurred: ', errors.join(', '));
    // showSnackbar(errors.join(', ') || 'Es ist ein Fehler aufgetreten', 'error');
    return <>{error_display()}</>;
  }

  // Default case: No errors found & Still loading => Show loading screen
  return <>{basic_loading_display()}</>;
}

import Box from '@mui/material/Box';
import { ResponsiveDrawer } from '../components/ResponsiveDrawer';
import { UserGrid } from '../components/Administration/UserGrid';

export function UserAdminPage() {
  return (
    <Box display={'flex'}>
      <ResponsiveDrawer />
      <Box
        sx={{
          paddingTop: '20px',
          paddingLeft: '20px',
          height: '60vh',
          width: { xs: 'calc(100% - 40px)', lg: 'calc(100% - 240px - 40px)' },
          position: 'absolute',
          left: { xs: 0, lg: 240 },
          top: 64,
        }}
      >
        <UserGrid />
      </Box>
    </Box>
  );
}

import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import ErrorIcon from '@mui/icons-material/Error';
import { ResponsiveDrawer } from '../components/ResponsiveDrawer';
import { Button, CardContent, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useSnackbar } from '../components/SnackbarProvider';
import { UserContext } from '../UserContext';
import { JobsiteDTO, UserService } from '../api/generated';
import { useNavigate } from 'react-router-dom';

export function InitialJobsiteSelect() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [selectedJobsite, setSelectedJobsite] = React.useState<JobsiteDTO>();
  const { user, reloadUser } = React.useContext(UserContext);

  function onJobsiteClick(v: any) {
    setSelectedJobsite(v);
  }

  React.useEffect(() => {
    (async () => {
      if (!selectedJobsite) return;
      return UserService.userControllerUpdateUser({
        authId: user?.authId || '',
        requestBody: {
          preferredJobsiteId: selectedJobsite.id,
        },
      });
    })()
      .then(() => {
        if (!selectedJobsite) return;
        reloadUser();
        showSnackbar(selectedJobsite?.name + ' ist jetzt der bevorzugte Standort.', 'success');
        navigate('/');
      })
      .catch((err) => {
        showSnackbar(err.message, 'error');
      });
  }, [selectedJobsite]);

  React.useEffect(() => {
    if (!user?.preferredJobsite) showSnackbar('Kein bevorzugter Standort gewählt', 'info');
  }, []);

  return (
    <Box>
      <ResponsiveDrawer />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
          paddingLeft: '20px',
          height: '80vh',
          width: { xs: 'calc(100% - 40px)', lg: 'calc(100% - 240px - 40px)' },
          position: 'absolute',
          left: { xs: 0, lg: 240 },
          top: 64,
        }}
      >
        <Card
          elevation={8}
          sx={{
            borderRadius: '10px',
            width: '40%',
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {user?.jobsites && user?.jobsites?.length > 0 ? (
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 2,
                flexGrow: 1,
              }}
            >
              <EditLocationIcon fontSize='large' />
              <Typography variant='h6' textAlign={'center'}>
                Bitte wählen Sie einen Standort aus der Liste aus.
              </Typography>
              <List>
                {user?.jobsites.map((v) => (
                  <ListItem disablePadding key={v.name}>
                    <ListItemButton onClick={() => onJobsiteClick(v)}>
                      <ListItemText primary={v.name} primaryTypographyProps={{ textAlign: 'center' }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          ) : (
            <>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', flexGrow: 1 }}>
                <ErrorIcon fontSize='large' />
                <Typography variant='h6' textAlign={'center'} display='block'>
                  Keine Standorte vorhanden
                </Typography>
                <Typography variant='h6' textAlign={'center'} display='block'>
                  Sie müssen zuerst von einem Administrator zugewiesen werden.
                </Typography>
              </CardContent>
              {user?.admin && user?.jobsites && user?.jobsites.length === 0 && (
                <Button
                  sx={{
                    margin: 2,
                    marginBottom: 6,
                    padding: 1,
                    paddingLeft: 2,
                    paddingRight: 2,
                    borderRadius: 2,
                    backgroundColor: 'primary.light',
                    '&:hover': {
                      backgroundColor: 'primary.main',
                    },
                    alignSelf: 'center',
                  }}
                  onClick={() => navigate('/user-admin')}
                >
                  <Typography variant='button' textAlign='center'>
                    <Box
                      component='span'
                      sx={{
                        cursor: 'pointer',
                        color: '#ffffff',
                      }}
                    >
                      Hier Standorte verwalten
                    </Box>
                    .
                  </Typography>
                </Button>
              )}
            </>
          )}
        </Card>
      </Box>
    </Box>
  );
}

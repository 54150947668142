import { FilterCompareOperatos, QueryDTO } from './generated';

export function buildQueryParams<T extends QueryDTO>(params: T): string {
  return JSON.stringify(params);
}

export function blobToDataURL(blob: Blob): Promise<string> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export interface ApiCallState<T> {
  error?: string;
  result?: T;
}

export interface PaginatedResult<T> {
  data: T[];
  total: number;
}

export interface ICapturerEvents extends Record<string, any> {
  capture_start_at: string;
  type: EventTypes;
}

export type EventTypes =
  | 'activity_started'
  | 'activity_ended'
  | 'datapoint_uploaded'
  | 'stitching_finished'
  | 'license_plate_read';

export function MUIOperatorMap(op?: string): FilterCompareOperatos {
  return op as FilterCompareOperatos;
}

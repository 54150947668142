import { Button, Card, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styles from './ImageCard.module.scss';
import { ApiLoadingGuard } from './ApiLoadingGuard';
import { ApiCallState } from '../api';
import { ImageCardError } from './ImageCardError';

interface DownloadableImageCardProps {
  height: string;
  image: ApiCallState<string>;
  showDownloadButton: boolean;
  downloadFileName: string;
}

interface NonDownloadableImageCardProps {
  height: string;
  image: ApiCallState<string>;
  showDownloadButton?: never;
  downloadFileName?: never;
}

export type ImageCardProps = DownloadableImageCardProps | NonDownloadableImageCardProps;

export function ImageCard(props: ImageCardProps) {
  const padding = 1; // The unit is rem

  return (
    <Card
      sx={{
        borderRadius: '10px',
        padding: `${padding}rem`,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: props.height,
      }}
      elevation={8}
    >
      <Typography variant='h6' alignSelf={'flex-start'}>
        Ladefläche
      </Typography>
      <ApiLoadingGuard apiStates={[props.image]} error_display={ImageCardError}>
        <div className={styles.image_container_outer}>
          <div className={styles.image_container_inner}>
            <img src={props.image.result} alt='LKW Ladefläche' className={styles.image} />
          </div>
        </div>

        {props.showDownloadButton && (
          <Button
            variant='contained'
            href={`data:application/octet-stream;base64,${props.image.result?.slice(22)}`}
            download={props.downloadFileName}
          >
            <FileDownloadIcon />
            Download
          </Button>
        )}
      </ApiLoadingGuard>
    </Card>
  );
}
